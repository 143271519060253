/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";

html, body { 
    height: 100%; 
}

:root {
    // --dashboard-gradient: linear-gradient(to left, #15b7d0, #b4be22);
    // --dashboard-gradient: linear-gradient(to left, #f31752, #d6e415);
    // --dashboard-gradient: linear-gradient(to left, #c3f1ec, #2fa4cb); 

    --dashboard-gradient: linear-gradient(to left, #4973cc, #c069e3); 



    // #5b5b5b, #80B9AD
  }


body { 
    margin: 0; 
    font-size: 18px;
    // margin-left: 200px;
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}

.txt-left {
    text-align: left !important;
    margin-left: 20%;
}
@media (min-width: 576px) {
    .ant-col-sm-12 {
        display: block;
        flex: 95 95 95%;
        max-width: 85%;
    }

}
@media screen and (max-width: 768px) {
    .txt-left {
        margin-left: 0; /* Reset margin for small screens */
    }
}

.ant-menu-sub.ant-menu-inline {
    // background: #ff8d02;
    border-radius: 0;
}

body {
    -moz-transform: scale(0.9, 0.9);
    zoom: 0.91;
    // zoom: 80%;
}

.button-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-button {
    width: 90px; /* Ensure both buttons have the same width */
    margin: 0 10px; /* Add some space between the buttons */
    // height: 50px;
    // line-height: 35px;
}

.ant-input {
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 2.5715;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all .3s;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 44px;
    padding: 0 11px;
}

.ant-btn {
   
    height: 44px;
  
}

.ant-form label {
    font-size: 14px;
    height: 35px;
}

.inner-content[_ngcontent-moe-c279] {
    padding: 24px;
    background: #fff;
    min-height: 70vh;
}


.ant-picker-input>input {
   
    line-height: 2.5715;
    
}

